import { template as template_b3c41815197446b0bd089514c2e8333c } from "@ember/template-compiler";
const FKText = template_b3c41815197446b0bd089514c2e8333c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
