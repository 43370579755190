import { template as template_32c9f882cc0a42efab39272407f03f30 } from "@ember/template-compiler";
const FKControlMenuContainer = template_32c9f882cc0a42efab39272407f03f30(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
