import { template as template_ab0d7e0e65bf4484a2c0f99333a78230 } from "@ember/template-compiler";
const FKLabel = template_ab0d7e0e65bf4484a2c0f99333a78230(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
