import { template as template_1377812e73294bd6abd8a7f70ccd0a5f } from "@ember/template-compiler";
const SidebarSectionMessage = template_1377812e73294bd6abd8a7f70ccd0a5f(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
